import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import axios from 'axios'
import { useEffect, useState } from 'react'
import Image from 'mui-image'
// import { Autoplay } from 'swiper'
import 'swiper/css'
import 'swiper/css/pagination'
// import { Swiper, SwiperSlide } from 'swiper/react'
import { Splide, SplideSlide, SplideTrack } from '@splidejs/react-splide'
import '@splidejs/react-splide/css'
import './styles/splide.css'

import ArrowCircleLeft from '../../assets/ArrowCircleLeft.svg'
import ArrowCircleRight from '../../assets/ArrowCircleRight.svg'
import Heading from '../Heading'
import Paragraph from '../Paragraph'

import nazeFarmsImage from '../../assets/nazeFarmsImage.png'

export default function Nazefarms () {
  const [nazeImages, setNazeImages] = useState([])
  const [hoverImage, setHoverImage] = useState({})

  const getGalleries = async () => {
    try {
      const getGalleryImages = await axios.get(
        `${
          process.env.REACT_APP_PROD_MODE === 'test'
            ? process.env.REACT_APP_API_URL_TEST
            : process.env.REACT_APP_API_URL_LIVE
        }/admin/get-images/nazeFarms`
      )

      setNazeImages([...getGalleryImages.data.allImages])
    } catch (error) {}
  }

  useEffect(() => {
    getGalleries()
  }, [])

  return (
    <Box
      sx={{
        backgroundColor: '#FBF7EC'
      }}
    >
      <Container
        sx={{
          paddingTop: { xs: '50px', md: '60px' }
        }}
        maxWidth='lg'
      >
        <Box
          sx={{
            display: { md: 'flex' },
            justifyContent: { md: 'space-between' },
            paddingTop: { xs: '50px', md: '100px' },
            paddingBottom: { md: '100px' }
          }}
        >
          <Box>
            <Image
              showLoading
              alt=''
              src={nazeFarmsImage}
              width='100%'
              height='100%'
            />
          </Box>
          <Box sx={{ paddingTop: '30px', width: { xs: '100%', md: '45%' } }}>
            <Splide
              hasTrack={false}
              options={{
                rewind: true,
                // autoplay: true,
                // type: 'loop',
                perPage: 1,
                pagination: true
                // gap: '1rem'
              }}
            >
              <SplideTrack>
                <SplideSlide>
                  <Heading
                    fontWeight='bold'
                    color='primary.main'
                    fontSize={{ xs: '30px', md: '48px' }}
                  >
                    Naze Farms
                  </Heading>
                  <Paragraph
                    paddingTop='20px'
                    fontSize={{ xs: '12px', md: '16px' }}
                    textAlign='left'
                    lineHeight={{ xs: '20px', md: '32px' }}
                    width={{ xs: '300px', md: '500px' }}
                    paddingBottom={{ xs: '20px', md: '0px' }}
                  >
                    Naze Farms, situated in the heart of Imo state, operates as
                    a large agricultural mega-hub. Employing the Naze Farms
                    Integrated Production Model, the farm implements a
                    self-sustaining agricultural system with minimal waste,
                    ensuring the production of clean, healthy, and
                    environmentally friendly goods.
                  </Paragraph>
                  <Paragraph
                    // paddingTop='20px'
                    fontSize={{ xs: '12px', md: '16px' }}
                    textAlign='left'
                    lineHeight={{ xs: '20px', md: '32px' }}
                    width={{ xs: '300px', md: '500px' }}
                    paddingBottom={{ xs: '20px', md: '0px' }}
                  >
                    At the forefront of the OneCultur campaign, Naze Farms
                    combines biological, energy, and informational sciences to
                    drive continuous cutting-edge innovation and aims to
                    revolutionize the current Agri-business landscape in
                    Nigeria.
                  </Paragraph>
                  <Paragraph
                    // paddingTop='20px'
                    fontSize={{ xs: '12px', md: '16px' }}
                    textAlign='left'
                    lineHeight={{ xs: '20px', md: '32px' }}
                    width={{ xs: '300px', md: '500px' }}
                    paddingBottom={{ xs: '20px', md: '0px' }}
                  >
                    Spanning across 3.5 hectares, the hub enjoys a strategic
                    location near the commercial district, and future plans
                    include the establishment of a supermarket, a livestock
                    smoking kiln, and a chicken processing plant on the
                    premises.
                  </Paragraph>
                </SplideSlide>
                <SplideSlide>
                  <Heading
                    fontWeight='bold'
                    color='primary.main'
                    fontSize={{ xs: '30px', md: '48px' }}
                  >
                    Naze Farms
                  </Heading>
                  <Paragraph
                    paddingTop='20px'
                    fontSize={{ xs: '12px', md: '16px' }}
                    textAlign='left'
                    lineHeight={{ xs: '20px', md: '32px' }}
                    width={{ xs: '300px', md: '500px' }}
                    paddingBottom={{ xs: '20px', md: '0px' }}
                  >
                    Naze Farms, situated in the heart of Imo state, operates as
                    a large agricultural mega-hub. Employing the Naze Farms
                    Integrated Production Model, the farm implements a
                    self-sustaining agricultural system with minimal waste,
                    ensuring the production of clean, healthy, and
                    environmentally friendly goods.
                  </Paragraph>
                  <Paragraph
                    // paddingTop='20px'
                    fontSize={{ xs: '12px', md: '16px' }}
                    textAlign='left'
                    lineHeight={{ xs: '20px', md: '32px' }}
                    width={{ xs: '300px', md: '500px' }}
                    paddingBottom={{ xs: '20px', md: '0px' }}
                  >
                    At the forefront of the OneCultur campaign, Naze Farms
                    combines biological, energy, and informational sciences to
                    drive continuous cutting-edge innovation and aims to
                    revolutionize the current Agri-business landscape in
                    Nigeria.
                  </Paragraph>
                  <Paragraph
                    // paddingTop='20px'
                    fontSize={{ xs: '12px', md: '16px' }}
                    textAlign='left'
                    lineHeight={{ xs: '20px', md: '32px' }}
                    width={{ xs: '300px', md: '500px' }}
                    paddingBottom={{ xs: '20px', md: '0px' }}
                  >
                    Spanning across 3.5 hectares, the hub enjoys a strategic
                    location near the commercial district, and future plans
                    include the establishment of a supermarket, a livestock
                    smoking kiln, and a chicken processing plant on the
                    premises.
                  </Paragraph>
                </SplideSlide>
                <SplideSlide>
                  <Heading
                    fontWeight='bold'
                    color='primary.main'
                    fontSize={{ xs: '30px', md: '48px' }}
                  >
                    Naze Farms
                  </Heading>
                  <Paragraph
                    paddingTop='20px'
                    fontSize={{ xs: '12px', md: '16px' }}
                    textAlign='left'
                    lineHeight={{ xs: '20px', md: '32px' }}
                    width={{ xs: '300px', md: '500px' }}
                    paddingBottom={{ xs: '20px', md: '0px' }}
                  >
                    Naze Farms, situated in the heart of Imo state, operates as
                    a large agricultural mega-hub. Employing the Naze Farms
                    Integrated Production Model, the farm implements a
                    self-sustaining agricultural system with minimal waste,
                    ensuring the production of clean, healthy, and
                    environmentally friendly goods.
                  </Paragraph>
                  <Paragraph
                    // paddingTop='20px'
                    fontSize={{ xs: '12px', md: '16px' }}
                    textAlign='left'
                    lineHeight={{ xs: '20px', md: '32px' }}
                    width={{ xs: '300px', md: '500px' }}
                    paddingBottom={{ xs: '20px', md: '0px' }}
                  >
                    At the forefront of the OneCultur campaign, Naze Farms
                    combines biological, energy, and informational sciences to
                    drive continuous cutting-edge innovation and aims to
                    revolutionize the current Agri-business landscape in
                    Nigeria.
                  </Paragraph>
                  <Paragraph
                    // paddingTop='20px'
                    fontSize={{ xs: '12px', md: '16px' }}
                    textAlign='left'
                    lineHeight={{ xs: '20px', md: '32px' }}
                    width={{ xs: '300px', md: '500px' }}
                    paddingBottom={{ xs: '20px', md: '0px' }}
                  >
                    Spanning across 3.5 hectares, the hub enjoys a strategic
                    location near the commercial district, and future plans
                    include the establishment of a supermarket, a livestock
                    smoking kiln, and a chicken processing plant on the
                    premises.
                  </Paragraph>
                </SplideSlide>
              </SplideTrack>
              <div className='splide__arrows'>
                <button className='splide__arrow splide__arrow--prev'>
                  <img
                    alt=''
                    src={ArrowCircleLeft}
                    width='100%'
                    height='100%'
                  />
                </button>
                <button className='splide__arrow splide__arrow--next'>
                  <img
                    alt=''
                    src={ArrowCircleRight}
                    width='100%'
                    height='100%'
                  />
                </button>
              </div>
            </Splide>
          </Box>
        </Box>
        <Box sx={{ paddingTop: { xs: '40px', md: '50px' } }}>
          {nazeImages.length > 0 && (
            <>
              <Paragraph
                display='flex'
                justifyContent={{ xs: 'center', md: 'flex-start' }}
                color='primary.main'
                fontWeight='bold'
                fontSize={{ xs: '25px', md: '48px' }}
                paddingBottom={{ xs: '15px', md: '25px' }}
              >
                Images of Naze Farms
              </Paragraph>
              <Box sx={{ display: { xs: 'none', md: 'block' } }}>
                <Splide
                  hasTrack={false}
                  options={{
                    rewind: true,
                    autoplay: true,
                    type: 'loop',
                    perPage: 6,
                    pagination: false,
                    gap: '1rem'
                  }}
                >
                  <SplideTrack>
                    {nazeImages.length > 0 &&
                      nazeImages.map(({ image }, index) => (
                        <SplideSlide
                          onMouseLeave={() =>
                            setHoverImage({ status: false, index })
                          }
                          onMouseEnter={() =>
                            setHoverImage({ status: true, index })
                          }
                          style={{
                            width:
                              hoverImage.status && hoverImage.index === index
                                ? '30%'
                                : '15%',
                            transition: 'width 0.3s ease-in-out'
                          }}
                          key={index}
                        >
                          <Box>
                            <Image
                              src={image}
                              alt={index}
                              width='100%'
                              height='100%'
                            />
                          </Box>
                        </SplideSlide>
                      ))}
                  </SplideTrack>
                  <div className='splide__arrows'>
                    <button className='splide__arrow splide__arrow--prev'>
                      <img
                        alt=''
                        src={ArrowCircleLeft}
                        width='100%'
                        height='100%'
                      />
                    </button>
                    <button className='splide__arrow splide__arrow--next'>
                      <img
                        alt=''
                        src={ArrowCircleRight}
                        width='100%'
                        height='100%'
                      />
                    </button>
                  </div>
                </Splide>
              </Box>
            </>
          )}
          {nazeImages.length > 0 && (
            <Box sx={{ display: { xs: 'block', md: 'none' } }}>
              <Splide
                hasTrack={false}
                options={{
                  rewind: true,
                  autoplay: true,
                  type: 'loop',
                  perPage: 1,
                  pagination: false,
                  gap: '1rem'
                }}
              >
                <SplideTrack>
                  {nazeImages.map(({ image }, index) => (
                    <SplideSlide
                      onMouseLeave={() =>
                        setHoverImage({ status: false, index })
                      }
                      onMouseEnter={() =>
                        setHoverImage({ status: true, index })
                      }
                      style={{
                        width:
                          hoverImage.status && hoverImage.index === index
                            ? '30%'
                            : '15%',
                        transition: 'width 0.3s ease-in-out'
                      }}
                      key={index}
                    >
                      <Box>
                        <Box>
                          <Image
                            src={image}
                            alt={index}
                            width='100%'
                            height='100%'
                          />
                        </Box>
                      </Box>
                    </SplideSlide>
                  ))}
                </SplideTrack>
                <div className='splide__arrows'>
                  <button className='splide__arrow splide__arrow--prev'>
                    <img
                      alt=''
                      src={ArrowCircleLeft}
                      width='100%'
                      height='100%'
                    />
                  </button>
                  <button className='splide__arrow splide__arrow--next'>
                    <img
                      alt=''
                      src={ArrowCircleRight}
                      width='100%'
                      height='100%'
                    />
                  </button>
                </div>
              </Splide>
            </Box>
          )}
        </Box>
      </Container>
    </Box>
  )
}
