import Stack from '@mui/material/Stack'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Pagination from '@mui/material/Pagination'

import binImage from '../../assets/binImage.svg'

export default function AdminImagesViewer ({
  images, perPage,
  hasNext,
  hasPrevious,
  total,
  currentPage,
  setPerPage,
  setHasNext,
  setHasPrevious,
  setTotal,
  setCurrentPage,
  setImages,
  deleteImage
}) {
  return (
    <Box sx={{
      paddingBottom: '70px'
    }}
    >
      {images.length < 1 &&

        <Stack
          sx={{ paddingTop: '150px' }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              fontFamily: 'Fira Code',
              fontWeight: 500,
              fontSize: '25px'
            }}
          >
            No image here
          </Box>
          <Box
            sx={{
              color: '#82918E',
              display: 'flex',
              justifyContent: 'center',
              fontFamily: 'Fira Code',
              fontWeight: 400
            }}
          >
            Add images here
          </Box>
        </Stack>}
      {images.length >= 1 &&
        <Box>
          <Box
            sx={{
              paddingTop: '50px',
              minHeight: '80vh'
            }}
          >
            <Grid spacing={4} container>
              {images.map((item, index) => (
                <Grid key={index} item xs={12} md={4} lg={3}>
                  <Box
                    sx={{
                      position: 'relative'
                    }}
                  >
                    <img alt='' src={item.image} width='100%' height='100%' />
                    <Box
                      onClick={() => deleteImage(item._id, images, setImages)}
                      sx={{
                        position: 'absolute',
                        right: ' 5%',
                        top: '5%',
                        cursor: 'pointer'
                      }}
                    >
                      <img alt='' src={binImage} width='100%' height='100%' />
                    </Box>
                  </Box>
                </Grid>
              ))}
            </Grid>
          </Box>
          <Box
            sx={{
              display: 'flex',
              justifyContent: { xs: 'center', md: 'flex-end' }
            }}
          >
            <Pagination
              sx={{
                '& .css-10w330c-MuiButtonBase-root-MuiPaginationItem-root.Mui-selected': {
                  backgroundColor: 'primary.main',
                  color: 'white'
                }
              }}
              page={currentPage}
              hideNextButton={!hasNext}
              hidePrevButton={!hasPrevious}
              // getItemAriaLabel={(type, page, selected) => console.log('this is details', type, page, selected)}
              onChange={(e, page) => setCurrentPage(parseInt(page))}
              count={Math.ceil(total / perPage)} shape='rounded'
            />
          </Box>
        </Box>}

    </Box>
  )
}
