import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import Stack from '@mui/material/Stack'
import Image from 'mui-image'
import { useState } from 'react'

import { Splide, SplideSlide, SplideTrack } from '@splidejs/react-splide'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Pagination, Autoplay, Navigation } from 'swiper/modules'
import 'swiper/css'
import 'swiper/css/pagination'

import ArrowCircleLeft from '../../../assets/ArrowCircleLeft.svg'
import ArrowCircleRight from '../../../assets/ArrowCircleRight.svg'

import agricSlider1 from '../../../assets/agriculture/agricGallery1.png'
import agricSlider2 from '../../../assets/agriculture/agricGallery2.png'
import agricSlider3 from '../../../assets/agriculture/agricGallery3.png'
import agricSlider4 from '../../../assets/agriculture/agricGallery4.png'
import agricSlider5 from '../../../assets/agriculture/agricGallery5.png'
import agricSlider6 from '../../../assets/agriculture/agricGallery6.png'
import agricSlider7 from '../../../assets/agriculture/agricGallery7.png'
import agricSlider8 from '../../../assets/agriculture/agricGallery8.png'
import agricSlider9 from '../../../assets/agriculture/agricGallery9.png'
import agricSlider10 from '../../../assets/agriculture/agricGallery10.png'
import agricSlider11 from '../../../assets/agriculture/agricGallery11.png'
import agricSlider12 from '../../../assets/agriculture/agricGallery12.png'

import pic1 from '../../../assets/agriculture/pic1.png'
import pic2 from '../../../assets/agriculture/pic2.png'
import pic3 from '../../../assets/agriculture/pic3.png'
import pic4 from '../../../assets/agriculture/pic4.png'

const galleryImages = [
  agricSlider1,
  agricSlider2,
  agricSlider3,
  agricSlider4,
  agricSlider5,
  agricSlider6,
  agricSlider7,
  agricSlider8,
  agricSlider9,
  agricSlider10,
  agricSlider11,
  agricSlider12
]

const sliderImages = [pic1, pic2, pic3, pic4]

const points = [
  {
    heading: 'Thoughtful Land and Crop Management:',
    para: `The location of our farm was meticulously chosen. It boasts untouched, 
    virgin land near a water body, promoting biodiversity conservation, and it's strategically positioned with 
    accessible roads and well away from residential areas. The land preparation process involved clearing with 
    heavy machinery, site survey, ploughing, and tilling, making beds for vegetable gardens, and digging holes for 
    agroforestry crops like pawpaw. We employ both direct seeding and nursery sowing methods, depending on the crop, 
    and our fertilization practices enrich the soil with compost manure, EMAS (Effective Microorganism Activated Solution),
     and various organic sources.
    `
  },
  {
    heading: 'Advanced Irrigation, Pest Management, and Harvesting',
    para: `Our irrigation methods vary, with Drip and Trickle Irrigation for market garden products 
    and Sprinkler Irrigation for agroforestry and market garden crops. Recent additions include a 
    water reservoir and a Compost Tea Well to enhance irrigation and nutrient supply. We manage pests 
    with herbicides and pesticides, employing nets and scarecrows to deter birds and rodents. Harvesting is 
    primarily done by hand using cutlasses or knives to minimize crop damage. The harvested crops are then sorted, 
    measured, and bagged for sale. While storage is minimal due to quick transitions from harvest to sales, 
    we have well-aerated transition storage for temporary needs.`
  },
  {
    heading: 'Sustainability, Community Involvement, and Future Plans:',
    para: `We prioritize sustainability through practices like crop rotation, waste recycling, and
     waste management. We also incorporate renewable energy sources, such as solar-powered systems for 
     water pumping. Our commitment to community involvement includes staff training and opportunities for 
     local community members to work and learn from our farm. Looking ahead, we have ambitious plans, including the 
     establishment of a research institute, a feed mill, and engaging in ecotourism. Our dedication extends beyond farming; 
     it encompasses environmental stewardship, community engagement, and a sustainable approach to agriculture.`
  }
]

export default function Agriculture () {
  const [hoverImage, setHoverImage] = useState({})

  return (
    <Box
      sx={{
        backgroundColor: '#FBF7EC'
      }}
    >
      <Container
        sx={{
          paddingTop: { xs: '50px', md: '60px' },
          paddingLeft: { xs: '20px', md: '120px' },
          paddingRight: { xs: '20px', md: '120px' },
          minHeight: '100vh'
        }}
        maxWidth='lg'
      >
        <Stack spacing={4}>
          <Box
            sx={{
              fontFamily: 'Bodoni Moda',
              fontWeight: 700,
              fontSize: { xs: '30px', md: '40px' },
              color: 'primary.main',
              display: 'flex',
              justifyContent: 'center',
              textAlign: 'center'
            }}
          >
            Welcome to <br />
            Our Crop Farming Venture
          </Box>
          <Box
            sx={{
              textAlign: 'center'
            }}
          >
            In Ngor Okpala, our crop farming journey began in July 2022, with a
            focus on a diverse array of crops. These include cucumber, pawpaw,
            groundnut, sweet corn, yellow corn, bitterleaf, okra, tomatoes,
            watermelon, cabbage, carrot, onions, guava, orange, mango, lime,
            lemon, avocado, local pear, bush mango (ogbono), oil palm, coconut,
            queen palms, sweet potato, yam, moringa, and cassava. Each crop
            choice was carefully considered to meet market demand, enhance
            market value through scarcity, or serve dual purposes in soil
            improvement.
          </Box>
          <Swiper
            pagination={true}
            autoplay={{
              delay: 2500,
              disableOnInteraction: false
            }}
            modules={[Pagination, Autoplay]}
            className='mySwiper'
          >
            {sliderImages.map((item, index) => {
              return (
                <SwiperSlide key={index}>
                  <Image src={item} alt='' width='100%' height='100%' />
                </SwiperSlide>
              )
            })}
          </Swiper>

          <Box
            sx={{
              paddingTop: '20px'
            }}
          />
          {points.map(({ heading, para }, index) => (
            <Box key={index}>
              <Box
                sx={{
                  backgroundColor: '#fff',
                  padding: '30px',
                  borderBottom:
                    index + 1 === points.length ? '2px solid #F69220' : 'none'
                }}
              >
                <Stack spacing={2}>
                  <Box
                    sx={{
                      color: 'primary.main',
                      fontWeight: 900
                    }}
                  >
                    {heading}
                  </Box>
                  <Box>{para}</Box>
                </Stack>
              </Box>
            </Box>
          ))}
        </Stack>
      </Container>
      <Container maxWidth='lg'>
        <Box
          sx={{
            fontFamily: 'Bodoni Moda',
            fontWeight: 700,
            fontSize: { xs: '25px', md: '40px' },
            color: 'secondary.subMain',
            paddingTop: { xs: '40px', md: '70px' }
          }}
        >
          Agriculture Gallery
        </Box>
        <Box
          sx={{
            paddingTop: { xs: '30px', md: '50px' }
          }}
        >
          <Box sx={{ display: { xs: 'block', md: 'none' } }}>
            <Splide
              hasTrack={false}
              options={{
                autoplay: true,
                type: 'loop',
                perPage: 2,
                pagination: false,
                gap: '1rem'
              }}
            >
              <SplideTrack>
                {galleryImages.map((item, index) => (
                  <SplideSlide
                    style={{
                      width:
                        hoverImage.status && hoverImage.index === index
                          ? '30%'
                          : '20%',
                      transition: 'width 0.3s ease-in-out'
                    }}
                    key={index}
                    onMouseLeave={() => setHoverImage({ status: false, index })}
                    onMouseEnter={() => setHoverImage({ status: true, index })}
                  >
                    <Box>
                      <Image src={item} alt='' width='100%' height='100%' />
                    </Box>
                  </SplideSlide>
                ))}
              </SplideTrack>
              <div className='splide__arrows'>
                <button className='splide__arrow splide__arrow--prev'>
                  <img
                    alt=''
                    src={ArrowCircleLeft}
                    width='120%'
                    height='100%'
                  />
                </button>
                <button className='splide__arrow splide__arrow--next'>
                  <img
                    alt=''
                    src={ArrowCircleRight}
                    width='120%'
                    height='100%'
                  />
                </button>
              </div>
            </Splide>
          </Box>
          <Box sx={{ display: { xs: 'none', md: 'block' } }}>
            <Splide
              hasTrack={false}
              options={{
                autoplay: true,
                type: 'loop',
                perPage: 5,
                pagination: false,
                gap: '1rem'
              }}
            >
              <SplideTrack>
                {galleryImages.map((item, index) => (
                  <SplideSlide
                    style={{
                      width:
                        hoverImage.status && hoverImage.index === index
                          ? '30%'
                          : '20%',
                      transition: 'width 0.3s ease-in-out'
                    }}
                    key={index}
                    onMouseLeave={() => setHoverImage({ status: false, index })}
                    onMouseEnter={() => setHoverImage({ status: true, index })}
                  >
                    <Box>
                      <Image src={item} alt='' width='100%' height='100%' />
                    </Box>
                  </SplideSlide>
                ))}
              </SplideTrack>
              <div className='splide__arrows'>
                <button className='splide__arrow splide__arrow--prev'>
                  <img
                    alt=''
                    src={ArrowCircleLeft}
                    width='120%'
                    height='100%'
                  />
                </button>
                <button className='splide__arrow splide__arrow--next'>
                  <img
                    alt=''
                    src={ArrowCircleRight}
                    width='120%'
                    height='100%'
                  />
                </button>
              </div>
            </Splide>
          </Box>
        </Box>
      </Container>
    </Box>
  )
}
