export const HOME_ROUTE = '/'
export const ABOUT_US_ROUTE = '/about-us'
export const OUR_FARMS_ROUTE = '/our-farms'
export const ABOUT_ONECUlTUR = `${OUR_FARMS_ROUTE}/onecultur`
export const ABOUT_NAZEFARMS = `${OUR_FARMS_ROUTE}/nazefarms`
export const ABOUT_OKAPALAFARMS = `${OUR_FARMS_ROUTE}/okpalafarms`
export const ABOUT_OKAPALAFARMS_AQUACULTURE = `${ABOUT_OKAPALAFARMS}/aquaculture`
export const ABOUT_OKAPALAFARMS_APICULTURE = `${ABOUT_OKAPALAFARMS}/apiculture`
export const ABOUT_OKAPALAFARMS_AGRICULTURE = `${ABOUT_OKAPALAFARMS}/agriculture`
export const ABOUT_OKAPALAFARMS_RANCHCULTURE = `${ABOUT_OKAPALAFARMS}/ranchculture`
export const GALLERY_ROUTE = '/gallery'
export const CONTACT_ROUTE = '/contact'
export const INITIATIVES_ROUTE = '/initiatives'
export const ADMIN_LOGIN_ROUTE = '/admin/login'
export const ADMIN_HOME_ROUTE = '/admin/home'
export const UNSUBSCRIBE_ROUTE = '/unsubscribe'
