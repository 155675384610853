import CssBaseline from '@mui/material/CssBaseline'
import { ThemeProvider } from '@mui/material/styles'

import { Suspense } from 'react'
import { Route, Routes, Navigate } from 'react-router-dom'

import './App.css'
import Footer from './components/Footer'
import Loading from './components/Loading'
import Navbar from './components/Navbar'
import GetInTouch from './components/GetInTouch'

import AboutUs from './pages/AboutUs'
import Home from './pages/Home'
import OurFarms from './pages/OurFarms'
import Gallery from './components/Gallery'
import Contact from './components/Contact'
import Unsubscribe from './components/Unsubscribe'
import AdminLogin from './components/admin/AdminLogin'
import Nazefarms from './components/OurFarms/NazeFarms'
import OkpalaFarms from './components/OurFarms/OkpalaFarms'
import Aquaculture from './components/OurFarms/OkpalaFarms/Aquaculture'
import Apiculture from './components/OurFarms/OkpalaFarms/Apiculture'
import Agriculture from './components/OurFarms/OkpalaFarms/Agriculture'
import RanchCulture from './components/OurFarms/OkpalaFarms/Ranch'
import OneCultur from './components/OurFarms/OneCultur'
// import Initiatives from './pages/Initiatives'
import AdminHome from './components/admin/AdminHome'
import NotFound from './components/NotFound'
import ScrollToTop from './utils/ScrollToTop'
import {
  ABOUT_US_ROUTE,
  HOME_ROUTE,
  OUR_FARMS_ROUTE,
  GALLERY_ROUTE,
  CONTACT_ROUTE,
  ADMIN_LOGIN_ROUTE,
  ADMIN_HOME_ROUTE,
  UNSUBSCRIBE_ROUTE,
  ABOUT_OKAPALAFARMS,
  ABOUT_ONECUlTUR,
  ABOUT_NAZEFARMS,
  ABOUT_OKAPALAFARMS_AQUACULTURE,
  ABOUT_OKAPALAFARMS_APICULTURE,
  ABOUT_OKAPALAFARMS_AGRICULTURE,
  ABOUT_OKAPALAFARMS_RANCHCULTURE

  // INITIATIVES_ROUTE
} from './utils/routes'
import { theme } from './utils/theme'
import '@fontsource/dm-sans'
import '@fontsource/bodoni-moda'

function App () {
  return (
    <>
      <CssBaseline />
      <ThemeProvider theme={theme}>
        <Suspense fallback={<Loading />}>
          <Navbar />
          <ScrollToTop>
            <Routes>
              <Route path={ADMIN_HOME_ROUTE} element={<AdminHome />} />
              <Route path={ADMIN_LOGIN_ROUTE} element={<AdminLogin />} />
              <Route path={UNSUBSCRIBE_ROUTE} element={<Unsubscribe />} />
              <Route path={HOME_ROUTE} element={<Home />} />
              <Route path={ABOUT_US_ROUTE} element={<AboutUs />} />
              <Route path={ABOUT_OKAPALAFARMS} element={<OkpalaFarms />} />
              <Route
                path={ABOUT_OKAPALAFARMS_AQUACULTURE}
                element={<Aquaculture />}
              />
              <Route
                path={ABOUT_OKAPALAFARMS_APICULTURE}
                element={<Apiculture />}
              />
              <Route
                path={ABOUT_OKAPALAFARMS_AGRICULTURE}
                element={<Agriculture />}
              />
              <Route
                path={ABOUT_OKAPALAFARMS_RANCHCULTURE}
                element={<RanchCulture />}
              />
              <Route path={ABOUT_NAZEFARMS} element={<Nazefarms />} />
              <Route path={ABOUT_ONECUlTUR} element={<OneCultur />} />
              <Route path={OUR_FARMS_ROUTE} element={<OurFarms />} />
              <Route path={GALLERY_ROUTE} element={<Gallery />} />
              <Route path={CONTACT_ROUTE} element={<Contact />} />
              <Route
                path='/admin'
                element={<Navigate to={ADMIN_HOME_ROUTE} />}
              />
              <Route path='*' element={<NotFound />} />
            </Routes>
          </ScrollToTop>
          <GetInTouch />
          <Footer />
        </Suspense>
      </ThemeProvider>
    </>
  )
}

export default App
