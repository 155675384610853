import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import Stack from '@mui/material/Stack'
import Image from 'mui-image'

import { Swiper, SwiperSlide } from 'swiper/react'
import { Pagination, Autoplay, Navigation } from 'swiper/modules'
import 'swiper/css'
import 'swiper/css/pagination'

import ranSlider1 from '../../../assets/ranch/ranGallery1.png'
import ranSlider2 from '../../../assets/ranch/ranGallery2.png'
import ranSlider3 from '../../../assets/ranch/ranGallery3.png'
import ranSlider4 from '../../../assets/ranch/ranGallery4.png'
import ranSlider5 from '../../../assets/ranch/ranGallery5.png'
import ranSlider6 from '../../../assets/ranch/ranGallery6.png'
import ranSlider7 from '../../../assets/ranch/ranGallery7.png'
import ranSlider8 from '../../../assets/ranch/ranGallery8.png'
import ranSlider9 from '../../../assets/ranch/ranGallery9.png'
import ranSlider10 from '../../../assets/ranch/ranGallery10.png'
import ranSlider11 from '../../../assets/ranch/ranGallery11.png'
import ranSlider12 from '../../../assets/ranch/ranGallery12.png'

import pic1 from '../../../assets/ranch/pic1.png'
import pic2 from '../../../assets/ranch/pic2.png'
import pic3 from '../../../assets/ranch/pic3.png'
import pic4 from '../../../assets/ranch/pic4.png'

const galleryImages = [
  ranSlider1,
  ranSlider2,
  ranSlider3,
  ranSlider4,
  ranSlider5,
  ranSlider6,
  ranSlider7,
  ranSlider8,
  ranSlider9,
  ranSlider10,
  ranSlider11,
  ranSlider12
]

const sliderImages = [pic1, pic2, pic3, pic4]

const points = [
  {
    heading: 'A Permanent Home for Livestock:',
    para: `We've established a permanent residence for our livestock, allowing for long-term planning.
     The careful selection of breeds, including Red Fulani/Mbororo cattle and Nigerian Dwarf Goats, 
     aligns perfectly with our ranching goals. Our pasture management, rotational grazing, and forage 
     cultivation contribute to well-balanced diets. We've built infrastructure,
     including loafing sheds for comfort and safety, and we maintain the health of our animals through
      disease prevention, temperature control, vaccination, and deworming.
    `
  },
  {
    heading: 'Prioritizing Animal Well-Being:',
    para: `Our feeding regimen, which meets specific nutritional needs, includes supplements 
    like moringa leaves and seeds. Low-stress handling techniques and ongoing 
    staff training prioritize the well-being of our livestock.
    `
  },
  {
    heading: 'Sustainability and Community Engagement:',
    para: `Environmental stewardship is a core value for us. We employ organic feeds, recycle waste for compost,
     and manage wastewater sustainably. Our commitment extends to community involvement and staff development. 
     Future plans involve enhanced health management, technological adoption, breed expansion, advanced breeding 
     research, and the establishment of a training hub for students.`
  }
]

export default function ranch () {
  return (
    <Box
      sx={{
        backgroundColor: '#FBF7EC'
      }}
    >
      <Container
        sx={{
          paddingTop: { xs: '50px', md: '60px' },
          paddingLeft: { xs: '20px', md: '120px' },
          paddingRight: { xs: '20px', md: '120px' },
          minHeight: '100vh'
        }}
        maxWidth='lg'
      >
        <Stack spacing={4}>
          <Box
            sx={{
              fontFamily: 'Bodoni Moda',
              fontWeight: 700,
              fontSize: { xs: '30px', md: '40px' },
              color: 'primary.main',
              display: 'flex',
              justifyContent: 'center',
              textAlign: 'center'
            }}
          >
            Welcome to <br />
            'Our Ranch at Ngor Okpala'
          </Box>
          <Box
            sx={{
              textAlign: 'center'
            }}
          >
            At 'Our Ranch at Ngor Okpala,' our unwavering passion for excellence
            in sustainability drives our commitment to specialized cattle and
            goat ranching. We've created a serene environment to ensure the
            well-being, health, and happiness of our livestock.
          </Box>
          <Swiper
            pagination={true}
            autoplay={{
              delay: 2500,
              disableOnInteraction: false
            }}
            modules={[Pagination, Autoplay]}
            className='mySwiper'
          >
            {sliderImages.map((item, index) => {
              return (
                <SwiperSlide key={index}>
                  <Image src={item} alt='' width='100%' height='100%' />
                </SwiperSlide>
              )
            })}
          </Swiper>

          <Box
            sx={{
              paddingTop: '20px'
            }}
          />
          {points.map(({ heading, para }, index) => (
            <Box key={index}>
              <Box
                sx={{
                  backgroundColor: '#fff',
                  padding: '30px',
                  borderBottom:
                    index + 1 === points.length ? '2px solid #F69220' : 'none'
                }}
              >
                <Stack spacing={2}>
                  <Box
                    sx={{
                      color: 'primary.main',
                      fontWeight: 900
                    }}
                  >
                    {heading}
                  </Box>
                  <Box>{para}</Box>
                </Stack>
              </Box>
            </Box>
          ))}
          <Box
            sx={{
              textAlign: 'center',
              fontSize: '18px'
            }}
          >
            Join us at 'Our Ranch at Ngor Okpala' as we pursue sustainable
            ranching, <br />
            community engagement, and the production of high-quality livestock
            products.
          </Box>
        </Stack>
      </Container>
      <Container maxWidth='lg'>
        <Box
          sx={{
            fontFamily: 'Bodoni Moda',
            fontWeight: 700,
            fontSize: { xs: '25px', md: '40px' },
            color: 'secondary.subMain',
            paddingTop: { xs: '40px', md: '70px' }
          }}
        >
          Ranch Gallery
        </Box>
        <Box
          sx={{
            paddingTop: { xs: '30px', md: '50px' },
            display: { xs: 'none', md: 'flex' }
          }}
        >
          <Swiper
            slidesPerView={3}
            spaceBetween={30}
            loop={true}
            navigation={true}
            autoplay={{
              delay: 2500,
              disableOnInteraction: false
            }}
            modules={[Navigation, Autoplay]}
            className='mySwiper'
          >
            {galleryImages.map((item, index) => (
              <SwiperSlide key={index}>
                <Image src={item} alt='' width='100%' height='100%' />
              </SwiperSlide>
            ))}
          </Swiper>
        </Box>
        <Box
          sx={{
            paddingTop: { xs: '30px', md: '50px' },
            display: { xs: 'flex', md: 'none' }
          }}
        >
          <Swiper
            slidesPerView={2}
            spaceBetween={30}
            navigation={true}
            loop={true}
            autoplay={{
              delay: 2500,
              disableOnInteraction: false
            }}
            modules={[Navigation, Autoplay]}
            className='mySwiper'
          >
            {galleryImages.map((item, index) => (
              <SwiperSlide key={index}>
                <Image src={item} alt='' width='100%' height='100%' />
              </SwiperSlide>
            ))}
          </Swiper>
        </Box>
      </Container>
    </Box>
  )
}
