import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import Stack from '@mui/material/Stack'
import Image from 'mui-image'
import { useState } from 'react'

import { Swiper, SwiperSlide } from 'swiper/react'
import { Splide, SplideSlide, SplideTrack } from '@splidejs/react-splide'
import { Pagination, Autoplay, Navigation } from 'swiper/modules'
import 'swiper/css'
import 'swiper/css/pagination'

import ArrowCircleLeft from '../../../assets/ArrowCircleLeft.svg'
import ArrowCircleRight from '../../../assets/ArrowCircleRight.svg'

import apiSlider1 from '../../../assets/apiculture/apiGallery1.png'
import apiSlider2 from '../../../assets/apiculture/apiGallery2.png'
import apiSlider3 from '../../../assets/apiculture/apiGallery3.png'
import apiSlider4 from '../../../assets/apiculture/apiGallery4.png'
import apiSlider5 from '../../../assets/apiculture/apiGallery5.png'
import apiSlider6 from '../../../assets/apiculture/apiGallery6.png'
import apiSlider7 from '../../../assets/apiculture/apiGallery7.png'
import apiSlider8 from '../../../assets/apiculture/apiGallery8.png'

import pic1 from '../../../assets/apiculture/pic1.png'
import pic2 from '../../../assets/apiculture/pic2.png'
import pic3 from '../../../assets/apiculture/pic3.png'
import pic4 from '../../../assets/apiculture/pic4.png'

const galleryImages = [
  apiSlider1,
  apiSlider2,
  apiSlider3,
  apiSlider4,
  apiSlider5,
  apiSlider6,
  apiSlider7,
  apiSlider8
]

const sliderImages = [pic1, pic2, pic3, pic4]

const points = [
  {
    heading: 'Strategic Apiary Site Selection:',
    para: `Our apiary site was carefully chosen near water bodies, shaded under tall trees, with stable soil for hive placement
     and proper drainage. We utilize top bar hives with a concrete design, 
    strategically placing 30 hives in parallel sets, ensuring appropriate distances for easy accessibility and attracting bees.`
  },
  {
    heading: 'Bee Colony Acquisition and Nutrition:',
    para: `We attract bees traditionally using a syrup made from palm wine, lemongrass, and honey, and also capture swarms
     from established colonies. Nectar sources like sunflowers,
      overripe pawpaw, cucumber flowers, and fruits provide essential nutrition for our bees.`
  },
  {
    heading: 'Sustainability, Environmental Practices, and Future Plans:',
    para: `We promote sustainability by planting sunflowers and flowering plants, 
    supporting bee health and reducing our environmental impact. Pesticides and chemicals are 
    avoided in managing the bees. Looking forward, our plans include expanding hive numbers, 
    research, integrating new apiculture techniques, experimenting with bee attraction methods, 
    and offering training programs for participants and university students.`
  }
]

export default function Apiculture () {
  const [hoverImage, setHoverImage] = useState({})

  return (
    <Box
      sx={{
        backgroundColor: '#FBF7EC'
      }}
    >
      <Container
        sx={{
          paddingTop: { xs: '50px', md: '60px' },
          paddingLeft: { xs: '20px', md: '120px' },
          paddingRight: { xs: '20px', md: '120px' },
          minHeight: '100vh'
        }}
        maxWidth='lg'
      >
        <Stack spacing={4}>
          <Box
            sx={{
              fontFamily: 'Bodoni Moda',
              fontWeight: 700,
              fontSize: { xs: '30px', md: '40px' },
              color: 'primary.main',
              display: 'flex',
              justifyContent: 'center',
              textAlign: 'center'
            }}
          >
            Welcome to
            <br />
            Our Apiculture Journey:
          </Box>
          <Box
            sx={{
              textAlign: 'center'
            }}
          >
            In December 2022, we ventured into bee farming (Apiculture) focusing
            on Apis mellifera adansonii. Our mission is to provide pure and
            healthy honey for consumers' health and commercial needs, along with
            repurposing beeswax for various products like candles, polish,
            soaps, perfumes, creams, and cosmetics.
          </Box>
          <Swiper
            pagination={true}
            autoplay={{
              delay: 2500,
              disableOnInteraction: false
            }}
            modules={[Pagination, Autoplay]}
            className='mySwiper'
          >
            {sliderImages.map((item, index) => {
              return (
                <SwiperSlide key={index}>
                  <Image src={item} alt='' width='100%' height='100%' />
                </SwiperSlide>
              )
            })}
          </Swiper>

          <Box
            sx={{
              paddingTop: '20px'
            }}
          />
          {points.map(({ heading, para }, index) => (
            <Box key={index}>
              <Box
                sx={{
                  backgroundColor: '#fff',
                  padding: '30px',
                  borderBottom:
                    index + 1 === points.length ? '2px solid #F69220' : 'none'
                }}
              >
                <Stack spacing={2}>
                  <Box
                    sx={{
                      color: 'primary.main',
                      fontWeight: 900
                    }}
                  >
                    {heading}
                  </Box>
                  <Box>{para}</Box>
                </Stack>
              </Box>
            </Box>
          ))}
        </Stack>
      </Container>
      <Container maxWidth='lg'>
        <Box
          sx={{
            fontFamily: 'Bodoni Moda',
            fontWeight: 700,
            fontSize: { xs: '25px', md: '40px' },
            color: 'secondary.subMain',
            paddingTop: { xs: '40px', md: '70px' }
          }}
        >
          Apiculture Gallery
        </Box>
        <Box
          sx={{
            paddingTop: { xs: '30px', md: '50px' }
          }}
        >
          <Box sx={{ display: { xs: 'block', md: 'none' } }}>
            <Splide
              hasTrack={false}
              options={{
                autoplay: true,
                type: 'loop',
                perPage: 2,
                pagination: false,
                gap: '1rem'
              }}
            >
              <SplideTrack>
                {galleryImages.map((item, index) => (
                  <SplideSlide
                    style={{
                      width:
                        hoverImage.status && hoverImage.index === index
                          ? '30%'
                          : '20%',
                      transition: 'width 0.3s ease-in-out'
                    }}
                    key={index}
                    onMouseLeave={() => setHoverImage({ status: false, index })}
                    onMouseEnter={() => setHoverImage({ status: true, index })}
                  >
                    <Box>
                      <Image src={item} alt='' width='100%' height='100%' />
                    </Box>
                  </SplideSlide>
                ))}
              </SplideTrack>
              <div className='splide__arrows'>
                <button className='splide__arrow splide__arrow--prev'>
                  <img
                    alt=''
                    src={ArrowCircleLeft}
                    width='120%'
                    height='100%'
                  />
                </button>
                <button className='splide__arrow splide__arrow--next'>
                  <img
                    alt=''
                    src={ArrowCircleRight}
                    width='120%'
                    height='100%'
                  />
                </button>
              </div>
            </Splide>
          </Box>
          <Box sx={{ display: { xs: 'none', md: 'block' } }}>
            <Splide
              hasTrack={false}
              options={{
                autoplay: true,
                type: 'loop',
                perPage: 5,
                pagination: false,
                gap: '1rem'
              }}
            >
              <SplideTrack>
                {galleryImages.map((item, index) => (
                  <SplideSlide
                    style={{
                      width:
                        hoverImage.status && hoverImage.index === index
                          ? '30%'
                          : '20%',
                      transition: 'width 0.3s ease-in-out'
                    }}
                    key={index}
                    onMouseLeave={() => setHoverImage({ status: false, index })}
                    onMouseEnter={() => setHoverImage({ status: true, index })}
                  >
                    <Box>
                      <Image src={item} alt='' width='100%' height='100%' />
                    </Box>
                  </SplideSlide>
                ))}
              </SplideTrack>
              <div className='splide__arrows'>
                <button className='splide__arrow splide__arrow--prev'>
                  <img
                    alt=''
                    src={ArrowCircleLeft}
                    width='120%'
                    height='100%'
                  />
                </button>
                <button className='splide__arrow splide__arrow--next'>
                  <img
                    alt=''
                    src={ArrowCircleRight}
                    width='120%'
                    height='100%'
                  />
                </button>
              </div>
            </Splide>
          </Box>
        </Box>
      </Container>
    </Box>
  )
}
