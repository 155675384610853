import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import Stack from '@mui/material/Stack'
import Image from 'mui-image'
import { useState } from 'react'

import { Splide, SplideSlide, SplideTrack } from '@splidejs/react-splide'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Pagination, Autoplay, Navigation } from 'swiper/modules'
import 'swiper/css'
import 'swiper/css/pagination'

import ArrowCircleLeft from '../../../assets/ArrowCircleLeft.svg'
import ArrowCircleRight from '../../../assets/ArrowCircleRight.svg'
import aquaSlider1 from '../../../assets/aquaSlider1.png'
import aquaSlider2 from '../../../assets/aquaSlider2.png'
import aquaSlider3 from '../../../assets/aquaSlider3.png'
import aquaSlider4 from '../../../assets/aquaSlider4.png'

import pic1 from '../../../assets/aquaGallery/pic1.png'
import pic2 from '../../../assets/aquaGallery/pic2.png'
import pic3 from '../../../assets/aquaGallery/pic3.png'
import pic4 from '../../../assets/aquaGallery/pic4.png'
import pic5 from '../../../assets/aquaGallery/pic5.png'
import pic6 from '../../../assets/aquaGallery/pic6.png'
import pic7 from '../../../assets/aquaGallery/pic7.png'
import pic8 from '../../../assets/aquaGallery/pic8.png'
import pic9 from '../../../assets/aquaGallery/pic9.png'
import pic10 from '../../../assets/aquaGallery/pic10.png'
import pic11 from '../../../assets/aquaGallery/pic11.png'
import pic12 from '../../../assets/aquaGallery/pic12.png'

const galleryImages = [
  pic1,
  pic2,
  pic3,
  pic4,
  pic5,
  pic6,
  pic7,
  pic8,
  pic9,
  pic10,
  pic11,
  pic12
]

const sliderImages = [aquaSlider1, aquaSlider2, aquaSlider3, aquaSlider4]

const points = [
  {
    heading: 'Strategic Site Selection and Pond Setup:',
    para: `Our farm is strategically located within an established farm, 
    ensuring easy access to essential resources. We've set up 36 circular 
    concrete ponds and an earthen pond, each 5ft deep and approximately 28m in diameter,
     with a reservoir capable of holding up to 50 metric tonnes of water.`
  },
  {
    heading: 'Water Management and Feeding Regimen:',
    para: `Water management involves the use of pipe-borne water and water hyacinth for aeration and filtration, 
    with waste water channeled to the earthen pond and crop cultivation areas. The farm was initially stocked with 3,140 fingerlings, 
    and our feeding regimen includes skretting feed, bleu crown feed, omega feed, and moringa leaves as supplements.`
  },
  {
    heading: 'Fish Health Management and Harvesting:',
    para: `We employ organic methods, primarily using moringa leaves, for fish health management. Biosecurity measures include 
    controlled human exposure and dedicated staff for fishery management. Fish maturity for live sales typically ranges from 4 to 6 months.`
  },
  {
    heading: 'Processing, Packaging, and Marketing:',
    para: `Our processing and packaging procedures involve cleaning, brining, spicing, 
    smoking, drying, weighing, and packaging.. We market our products in open/bush markets, to wholesalers, eateries, and supermarkets.`
  },
  {
    heading: 'Sustainability, and Future Plans:',
    para: `Our sustainability efforts involve re-channeling waste water to crop production areas, adherence to water quality regulations, and 
    internal waste management. In the future, we plan to expand our catfish stock, conduct research, integrate new techniques, establish a hatchery,
     and offer training programs for participants and university students. 
    Our commitment remains rooted in sustainable and responsible fish farming practices to provide high-quality products to our customers.`
  }
]

export default function Aquaculture () {
  const [hoverImage, setHoverImage] = useState({})

  return (
    <Box
      sx={{
        backgroundColor: '#FBF7EC'
      }}
    >
      <Container
        sx={{
          paddingTop: { xs: '50px', md: '60px' },
          paddingLeft: { xs: '20px', md: '120px' },
          paddingRight: { xs: '20px', md: '120px' },
          minHeight: '100vh'
        }}
        maxWidth='lg'
      >
        <Stack spacing={4}>
          <Box
            sx={{
              fontFamily: 'Bodoni Moda',
              fontWeight: 700,
              fontSize: { xs: '30px', md: '40px' },
              color: 'primary.main',
              display: 'flex',
              justifyContent: 'center',
              textAlign: 'center'
            }}
          >
            Welcome to <br /> Our Catfish Farming Journey
          </Box>
          <Box
            sx={{
              textAlign: 'center'
            }}
          >
            In July 2023, we embarked on our fish farming venture, with a
            specific focus on the Catfish species Clarias gariepinus. This
            choice was influenced by the species' prevalence in West Africa,
            rapid maturity, economic value, and versatility.
          </Box>
          <Swiper
            pagination={true}
            autoplay={{
              delay: 2500,
              disableOnInteraction: false
            }}
            modules={[Pagination, Autoplay]}
            className='mySwiper'
          >
            {sliderImages.map((item, index) => {
              return (
                <SwiperSlide key={index}>
                  <Image src={item} alt='' width='100%' height='100%' />
                </SwiperSlide>
              )
            })}
          </Swiper>

          <Box
            sx={{
              paddingTop: '20px'
            }}
          />
          {points.map(({ heading, para }, index) => (
            <Box key={index}>
              <Box
                sx={{
                  backgroundColor: '#fff',
                  padding: '30px',
                  borderBottom:
                    index + 1 === points.length ? '2px solid #F69220' : 'none'
                }}
              >
                <Stack spacing={2}>
                  <Box
                    sx={{
                      color: 'primary.main',
                      fontWeight: 900
                    }}
                  >
                    {heading}
                  </Box>
                  <Box>{para}</Box>
                </Stack>
              </Box>
            </Box>
          ))}
        </Stack>
      </Container>
      <Container maxWidth='lg'>
        <Box
          sx={{
            fontFamily: 'Bodoni Moda',
            fontWeight: 700,
            fontSize: { xs: '25px', md: '40px' },
            color: 'secondary.subMain',
            paddingTop: { xs: '40px', md: '70px' }
          }}
        >
          Aquaculture Gallery
        </Box>
        <Box
          sx={{
            paddingTop: { xs: '30px', md: '50px' }
          }}
        >
          <Box sx={{ display: { xs: 'block', md: 'none' } }}>
            <Splide
              hasTrack={false}
              options={{
                autoplay: true,
                type: 'loop',
                perPage: 2,
                pagination: false,
                gap: '1rem'
              }}
            >
              <SplideTrack>
                {galleryImages.map((item, index) => (
                  <SplideSlide
                    style={{
                      width:
                        hoverImage.status && hoverImage.index === index
                          ? '30%'
                          : '20%',
                      transition: 'width 0.3s ease-in-out'
                    }}
                    key={index}
                    onMouseLeave={() => setHoverImage({ status: false, index })}
                    onMouseEnter={() => setHoverImage({ status: true, index })}
                  >
                    <Box>
                      <Image src={item} alt='' width='100%' height='100%' />
                    </Box>
                  </SplideSlide>
                ))}
              </SplideTrack>
              <div className='splide__arrows'>
                <button className='splide__arrow splide__arrow--prev'>
                  <img
                    alt=''
                    src={ArrowCircleLeft}
                    width='120%'
                    height='100%'
                  />
                </button>
                <button className='splide__arrow splide__arrow--next'>
                  <img
                    alt=''
                    src={ArrowCircleRight}
                    width='120%'
                    height='100%'
                  />
                </button>
              </div>
            </Splide>
          </Box>
          <Box sx={{ display: { xs: 'none', md: 'block' } }}>
            <Splide
              hasTrack={false}
              options={{
                autoplay: true,
                type: 'loop',
                perPage: 5,
                pagination: false,
                gap: '1rem'
              }}
            >
              <SplideTrack>
                {galleryImages.map((item, index) => (
                  <SplideSlide
                    style={{
                      width:
                        hoverImage.status && hoverImage.index === index
                          ? '30%'
                          : '20%',
                      transition: 'width 0.3s ease-in-out'
                    }}
                    key={index}
                    onMouseLeave={() => setHoverImage({ status: false, index })}
                    onMouseEnter={() => setHoverImage({ status: true, index })}
                  >
                    <Box>
                      <Image src={item} alt='' width='100%' height='100%' />
                    </Box>
                  </SplideSlide>
                ))}
              </SplideTrack>
              <div className='splide__arrows'>
                <button className='splide__arrow splide__arrow--prev'>
                  <img
                    alt=''
                    src={ArrowCircleLeft}
                    width='120%'
                    height='100%'
                  />
                </button>
                <button className='splide__arrow splide__arrow--next'>
                  <img
                    alt=''
                    src={ArrowCircleRight}
                    width='120%'
                    height='100%'
                  />
                </button>
              </div>
            </Splide>
          </Box>
        </Box>
      </Container>
    </Box>
  )
}
