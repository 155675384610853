import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import Stack from '@mui/material/Stack'
import Image from 'mui-image'
import { Link } from 'react-router-dom'
import { useNavigate } from 'react-router-dom'
import { Splide, SplideSlide, SplideTrack } from '@splidejs/react-splide'

import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css'
import 'swiper/css/pagination'
import { Pagination, Autoplay } from 'swiper/modules'

import ArrowCircleLeft from '../../../assets/ArrowCircleLeft.svg'
import ArrowCircleRight from '../../../assets/ArrowCircleRight.svg'
import homeSlider1 from '../../../assets/ngor/homeSlider1.png'
import homeSlider2 from '../../../assets/ngor/homeSlider2.png'
import homeSlider3 from '../../../assets/ngor/homeSlider3.png'
import aquaImage from '../../../assets/ngor/aquaImage.png'
import agricImage from '../../../assets/ngor/apiImage.png'
import apiImage from '../../../assets/ngor/agricImage.png'
import ranchImage from '../../../assets/ngor/ranchImage.png'
import Button from '../../Button'
import {
  ABOUT_OKAPALAFARMS_AQUACULTURE,
  ABOUT_OKAPALAFARMS_APICULTURE,
  ABOUT_OKAPALAFARMS_AGRICULTURE,
  ABOUT_OKAPALAFARMS_RANCHCULTURE
} from '../../../utils/routes'

const sliderImages = [homeSlider1, homeSlider2, homeSlider3]

const farmsDescription = [
  {
    heading: 'Aquaculture',
    image: aquaImage,
    desc: `In July 2023, we ventured into fish farming, focusing on Clarias gariepinus. 
          Our strategic site includes 36 concrete ponds, an earthen pond and a 50-tonne reservoir
          . We prioritize fish health, sustainable practices, and quality products.
           Future plans involve a hatchery, stock expansion, innovative techniques and training programs.
            Join us in responsible, high-quality fish farming!`,
    link: ABOUT_OKAPALAFARMS_AQUACULTURE
  },
  {
    heading: 'Apiculture',
    image: apiImage,
    desc: `In December 2022, we embraced bee farming, focusing on Apis mellifera adansonii.
     Our mission: to provide pure honey and repurpose beeswax. We carefully selected our apiary site,
      promote sustainability and avoid pesticides. Future plans include hive expansion, 
      research, and training programs. 
    Join us on this sustainable journey!`,
    link: ABOUT_OKAPALAFARMS_APICULTURE
  },
  {
    heading: 'Agriculture',
    image: agricImage,
    desc: `Embark on an agricultural journey like no other, with a rich array of crops meticulously 
    chosen to meet market demand and elevate soil quality. Our location,
     with pristine land near water bodies, embraces biodiversity conservation and sustainability.
      Discover our commitment to responsible agriculture!`,
    link: ABOUT_OKAPALAFARMS_AGRICULTURE
  },
  {
    heading: 'The Ranch',
    image: ranchImage,
    desc: `Explore our carefully nurtured haven for thriving breeds. Join us on a journey of sustainability, community harmony, and premium livestock. Dive into ranching excellence!`,
    link: ABOUT_OKAPALAFARMS_RANCHCULTURE
  }
]

export default function OkpalaFarms () {
  let navigate = useNavigate()

  return (
    <Box
      sx={{
        backgroundColor: '#FBF7EC'
      }}
    >
      <Container
        sx={{
          paddingTop: { xs: '50px', md: '60px' },
          minHeight: '100vh'
        }}
        maxWidth='lg'
      >
        <Swiper
          pagination={true}
          autoplay={{
            delay: 2500,
            disableOnInteraction: false
          }}
          modules={[Pagination, Autoplay]}
          className='mySwiper'
        >
          {sliderImages.map((item, index) => {
            return (
              <SwiperSlide key={index}>
                <Image src={item} alt='' width='100%' height='200px' />
              </SwiperSlide>
            )
          })}
        </Swiper>
        <Box
          sx={{
            paddingTop: '20px'
          }}
        >
          <Stack spacing={2}>
            <Box
              sx={{
                fontFamily: 'Bodoni Moda',
                fontWeight: 700,
                fontSize: { xs: '30px', md: '40px' },
                color: 'primary.main',
                display: 'flex',
                justifyContent: ' center'
              }}
            >
              Ngor Okpala Farm
            </Box>
            <Stack
              sx={{
                color: '#333'
              }}
              spacing={1}
            >
              <Box>
                With the proven success of Naze Farms, Ngor Okpala is
                Onecultur’s next great farming project. Set on 50 hectares of
                prime farmland, the possibilities for growth are endless. With
                over 5 hectares already cleared earlier this year, Phase 1 at
                the Ngor Okpala farms has commenced. Land has been cultivated
                for growth of crops such as:
              </Box>
              <Box>- Maize on 2.5 hectares of land </Box>
              <Box> - Sweet potato on 1 hectare of land </Box>
              <Box>
                Dedicated to sustainable farming practices, we offer livestock
                ranching, diverse crop farming, apiculture, and catfish farming.
                Our commitment to well-being, environmental stewardship, and
                community engagement sets us apart in the world of agriculture.
              </Box>
            </Stack>
          </Stack>
          <Box
            sx={{
              paddingTop: '40px'
            }}
          >
            <Box
              sx={{
                paddingBottom: { xs: '50px', md: '100px' },
                display: { md: 'flex' },
                justifyContent: { md: 'space-between' }
              }}
            >
              <Box
                sx={{
                  paddingRight: { md: '100px' },
                  width: { md: '50%' }
                }}
              >
                <Image
                  src={aquaImage}
                  alt=''
                  style={{ width: '100%', height: '100%' }}
                />
              </Box>
              <Box
                sx={{
                  alignSelf: { md: 'center' },
                  width: { md: '50%' }
                }}
              >
                <Stack
                  spacing={2}
                  sx={{
                    paddingTop: '20px',
                    width: { md: '100%' }
                  }}
                >
                  <Box
                    sx={{
                      fontFamily: 'Bodoni Moda',
                      fontWeight: 700,
                      display: 'flex',
                      justifyContent: { xs: 'center', md: 'flex-start' },
                      fontSize: '22px'
                    }}
                  >
                    Aquaculture
                  </Box>
                  <Box
                    sx={{
                      color: '#666',
                      lineHeight: '31.992px'
                    }}
                  >
                    In July 2023, we ventured into fish farming, focusing on
                    Clarias gariepinus. Our strategic site includes 36 concrete
                    ponds, an earthen pond and a 50-tonne reservoir . We
                    prioritize fish health, sustainable practices, and quality
                    products. Future plans involve a hatchery, stock expansion,
                    innovative techniques and training programs. Join us in
                    responsible, high-quality fish farming!
                  </Box>
                  <Box
                  //  component={Link} to={ABOUT_OKAPALAFARMS_AQUACULTURE}
                  >
                    <Button
                      onClick={() => navigate(ABOUT_OKAPALAFARMS_AQUACULTURE)}
                      variant='outlined'
                      text='Show more'
                      borderRadius='14px'
                      color='primary.main'
                    />
                  </Box>
                </Stack>
              </Box>
            </Box>
            <Box
              sx={{
                paddingBottom: { xs: '50px', md: '100px' },
                display: { md: 'flex' },
                justifyContent: { md: 'space-between' }
              }}
            >
              <Box
                sx={{
                  width: { md: '50%' },
                  paddingBottom: { xs: '50px', md: 0 }
                }}
              >
                <Stack
                  spacing={2}
                  sx={{
                    paddingTop: '20px',
                    width: { md: '100%' },
                    alignSelf: { md: 'center' }
                  }}
                >
                  <Box
                    sx={{
                      fontFamily: 'Bodoni Moda',
                      fontWeight: 700,
                      display: 'flex',
                      justifyContent: { xs: 'center', md: 'flex-start' },
                      fontSize: '22px'
                    }}
                  >
                    Apiculture
                  </Box>
                  <Box
                    sx={{
                      color: '#666',
                      lineHeight: '31.992px'
                    }}
                  >
                    In December 2022, we embraced bee farming, focusing on Apis
                    mellifera adansonii. Our mission: to provide pure honey and
                    repurpose beeswax. We carefully selected our apiary site,
                    promote sustainability and avoid pesticides. Future plans
                    include hive expansion, research, and training programs.
                    Join us on this sustainable journey!
                  </Box>
                  <Box component={Link} to={ABOUT_OKAPALAFARMS_APICULTURE}>
                    <Button
                      variant='outlined'
                      text='Show more'
                      borderRadius='14px'
                      color='primary.main'
                    />
                  </Box>
                </Stack>
              </Box>
              <Box
                sx={{
                  paddingLeft: { md: '100px' },
                  width: { md: '50%' }
                }}
              >
                <Image
                  src={apiImage}
                  alt=''
                  style={{ width: '100%', height: '100%' }}
                />
              </Box>
            </Box>
            <Box
              sx={{
                paddingBottom: { xs: '50px', md: '100px' },
                display: { md: 'flex' },
                justifyContent: { md: 'space-between' }
              }}
            >
              <Box
                sx={{
                  paddingRight: { md: '100px' },
                  width: { md: '50%' }
                }}
              >
                <Image
                  src={agricImage}
                  alt=''
                  style={{ width: '100%', height: '100%' }}
                />
              </Box>
              <Box
                sx={{
                  alignSelf: { md: 'center' },
                  width: { md: '50%' }
                }}
              >
                <Stack
                  spacing={2}
                  sx={{
                    paddingTop: '20px'
                  }}
                >
                  <Box
                    sx={{
                      fontFamily: 'Bodoni Moda',
                      fontWeight: 700,
                      display: 'flex',
                      justifyContent: { xs: 'center', md: 'flex-start' },
                      fontSize: '22px'
                    }}
                  >
                    Agriculture
                  </Box>
                  <Box
                    sx={{
                      color: '#666',
                      lineHeight: '31.992px'
                    }}
                  >
                    Embark on an agricultural journey like no other, with a rich
                    array of crops meticulously chosen to meet market demand and
                    elevate soil quality. Our location, with pristine land near
                    water bodies, embraces biodiversity conservation and
                    sustainability. Discover our commitment to responsible
                    agriculture!`
                  </Box>
                  <Box component={Link} to={ABOUT_OKAPALAFARMS_AGRICULTURE}>
                    <Button
                      variant='outlined'
                      text='Show more'
                      borderRadius='14px'
                      color='primary.main'
                    />
                  </Box>
                </Stack>
              </Box>
            </Box>
            <Box
              sx={{
                paddingBottom: { xs: '50px', md: '100px' },
                display: { md: 'flex' },
                justifyContent: { md: 'space-between' }
              }}
            >
              <Box
                sx={{
                  alignSelf: { md: 'center' },
                  width: { md: '50%' },
                  paddingBottom: { xs: '50px', md: 0 }
                }}
              >
                <Stack
                  spacing={2}
                  sx={{
                    paddingTop: '20px'
                  }}
                >
                  <Box
                    sx={{
                      fontFamily: 'Bodoni Moda',
                      fontWeight: 700,
                      display: 'flex',
                      justifyContent: { xs: 'center', md: 'flex-start' },
                      fontSize: '22px'
                    }}
                  >
                    The Ranch
                  </Box>
                  <Box
                    sx={{
                      color: '#666',
                      lineHeight: '31.992px'
                    }}
                  >
                    Explore our carefully nurtured haven for thriving breeds.
                    Join us on a journey of sustainability, community harmony,
                    and premium livestock. Dive into ranching excellence!
                  </Box>
                  <Box component={Link} to={ABOUT_OKAPALAFARMS_RANCHCULTURE}>
                    <Button
                      variant='outlined'
                      text='Show more'
                      borderRadius='14px'
                      color='primary.main'
                    />
                  </Box>
                </Stack>
              </Box>
              <Box
                sx={{
                  paddingLeft: { md: '100px' },
                  width: { md: '50%' }
                }}
              >
                <Image
                  src={ranchImage}
                  alt=''
                  style={{ width: '100%', height: '100%' }}
                />
              </Box>
            </Box>
          </Box>
        </Box>
      </Container>
    </Box>
  )
}
