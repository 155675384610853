import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import Image from 'mui-image'

import oneCulturImage from '../../assets/oneCulturImage.png'
import Heading from '../Heading'
import Paragraph from '../Paragraph'

export default function OneCultur () {
  return (
    <Box
      sx={{
        display: { md: 'flex' },
        justifyContent: { md: 'space-between' },
        paddingTop: { xs: '50px', md: '60px' },
        paddingBottom: { md: '100px' },
        backgroundColor: '#FBF7EC'
      }}
    >
      <Container
        sx={{
          paddingTop: { xs: '50px', md: '60px' }
        }}
        maxWidth='lg'
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between'
          }}
        >
          <Box sx={{ paddingTop: '30px' }}>
            <Heading
              fontWeight='bold'
              color='primary.main'
              fontSize={{ xs: '30px', md: '48px' }}
            >
              OneCultur
              <br />
              Farms
            </Heading>
            <Paragraph
              paddingTop='20px'
              fontSize={{ xs: '12px', md: '16px' }}
              textAlign='left'
              lineHeight={{ xs: '20px', md: '32px' }}
              width={{ xs: '300px', md: '500px' }}
              paddingBottom={{ xs: '20px', md: '0px' }}
            >
              OneCultur presents an innovative approach to Agriculture,
              prioritizing environmental sustainability. Our focus is on
              achieving zero waste, zero downtime, and implementing a complete
              end-to-end value chain production system. Our ultimate goal is to
              foster employment opportunities, drive sustainable economic
              growth, and empower Nigerian youth by showcasing the allure of
              Agribusiness in Nigeria and beyond, making a positive impact
              across Africa.
            </Paragraph>
          </Box>
          <Box>
            <Image
              showLoading
              alt=''
              src={oneCulturImage}
              width='100%'
              height='100%'
            />
          </Box>
        </Box>
      </Container>
    </Box>
  )
}
