import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import Slide from '@mui/material/Slide'
import Grid from '@mui/material/Grid'
import Stack from '@mui/material/Stack'
import Image from 'mui-image'
import { useNavigate } from 'react-router-dom'

import Nazefarms from '../components/OurFarms/NazeFarms'
import OkpalaFarms from '../components/OurFarms/OkpalaFarms'
import OneCultur from '../components/OurFarms/OneCultur'
import oneCulturImage from '../assets/oneculturAbout.png'
import nazeFarmsImage from '../assets/nazeFarmsImage.png'
import okpalaFarms from '../assets/ngor/image1.png'
import Heading from '../components/Heading'
import {
  ABOUT_OKAPALAFARMS,
  ABOUT_ONECUlTUR,
  ABOUT_NAZEFARMS
} from '../utils/routes'

const farms = [
  {
    img: okpalaFarms,
    title: 'Ngor Okpala Farms',
    link: ABOUT_OKAPALAFARMS
  },
  // {
  //   img: oneCulturImage,
  //   title: 'OneCultur',
  //   link: ABOUT_ONECUlTUR
  // },
  {
    img: nazeFarmsImage,
    title: 'Naze Farms',
    link: ABOUT_NAZEFARMS
  }
]

export default function OurFarms () {
  const navigate = useNavigate()

  return (
    <Slide direction='left' in mountOnEnter unmountOnExit>
      <Box sx={{ backgroundColor: '#FBF7EC', minHeight: '100vh' }}>
        <Container
          sx={{
            paddingTop: { xs: '50px', md: '60px' }
          }}
          maxWidth='lg'
        >
          <Grid
            spacing={2}
            container
            direction='row'
            justifyContent='center'
            alignItems='center'
          >
            {farms.map(({ img, title, link }, index) => (
              <Grid
                sx={{
                  cursor: 'pointer'
                }}
                onClick={() => navigate(link)}
                key={index}
                item
                xs={12}
                md={4}
              >
                <Stack
                  sx={{
                    transition: 'transform 0.3s ease-in-out',
                    '&:hover': {
                      transform: 'scale(1.1)' // Increase the scale factor for zoom-in effect
                    }
                  }}
                >
                  <Box
                    sx={{
                      width: '100%',
                      height: '100%',
                      display: 'flex',
                      justifyContent: 'center'
                    }}
                  >
                    <Image src={img} width='300px' height='300px' alt='' />
                  </Box>
                  <Heading
                    fontWeight='bold'
                    color='primary.main'
                    fontSize={{ xs: '20px', md: '28px' }}
                    display='flex'
                    justifyContent='center'
                  >
                    {title}
                  </Heading>
                </Stack>
              </Grid>
            ))}
          </Grid>
        </Container>
      </Box>
    </Slide>
  )
}
